import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Link, Switch, Redirect } from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import { Homepage } from './components/homepage/homepage';
import { Navbar } from './components/navbar';
import { Lineups } from './components/lineups/lineups';

import './index.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
        <div className="App">
          <Navbar></Navbar>
          <Switch>
            <Route exact path="/" render={() => {
              return (
                <Redirect to="/home"></Redirect>
              )
            }} />
            <Route exact path="/lineups" component={Lineups} />
            <Route exact path="/home" component={Homepage} />
          </Switch>
        </div>
      </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
