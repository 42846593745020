import React, { useState } from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { AppBar, Toolbar, Typography, Button, IconButton, SwipeableDrawer, List, Box, Divider, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SportsHockeyOutlinedIcon from '@mui/icons-material/SportsHockeyOutlined';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: '8px',
    },
    title: {
      flexGrow: 1,
    },
  }),
);

export const Navbar = () => {
  const classes = useStyles();

  const [ drawerOpen, setDrawerOpen ] = useState(false);

  const ToggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <div className={classes.root}>
      <React.Fragment>
        <SwipeableDrawer
            anchor={'left'}
            open={drawerOpen}
            onClose={ToggleDrawer}
            onOpen={() => {}}
        >
          <Box sx={{ width: 250, height: '100%', textAlign: 'center'}} style={{backgroundColor: '#3f51b5', color: '#fff'}} role="presentation">
            <h2>Pages</h2>
            <Divider style={{backgroundColor: '#fff'}}></Divider>
            <List>
              <ListItemButton component={Link} to='/home'>
                <ListItemIcon>
                  <HomeOutlinedIcon style={{color: '#fff'}}></HomeOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
              <ListItemButton component={Link} to='/lineups'>
                <ListItemIcon>
                  <SportsHockeyOutlinedIcon style={{color: '#fff'}}></SportsHockeyOutlinedIcon>
                </ListItemIcon>
                <ListItemText primary="Lineup" />
              </ListItemButton>
            </List>
            <Divider style={{backgroundColor: '#fff'}}></Divider>
          </Box>
        </SwipeableDrawer>
      </React.Fragment>
      <AppBar position="static" style={{backgroundColor: "#3f51b5"}}>
        <Toolbar>
          <IconButton onClick={ToggleDrawer} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" className={classes.title}>
            Grand Speed
          </Typography>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
    </div>
  );
}