import { StatsDto } from "../classes/stats";

class HardCodedData {
     public static GetStats(): StatsDto[] {
        const roster: StatsDto[] = [];

        roster.push({
            Id: 1,
            Name: 'Adam',
            Games: 1,
            Goals: 3,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 6,
            Name: 'Casey',
            Games: 1,
            Goals: 0,
            Assists: 1,
            PIM: 0,
        });

        roster.push({
            Id: 3,
            Name: 'Jasmine',
            Games: 1,
            Goals: 0,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 4,
            Name: 'Richard',
            Games: 1,
            Goals: 0,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 5,
            Name: 'Calvin',
            Games: 1,
            Goals: 1,
            Assists: 0,
            PIM: 2,
        });

        roster.push({
            Id: 8,
            Name: 'Sean M.',
            Games: 1,
            Goals: 0,
            Assists: 1,
            PIM: 0,
        });

        roster.push({
            Id: 2,
            Name: 'Mitch',
            Games: 1,
            Goals: 1,
            Assists: 1,
            PIM: 0,
        });

        roster.push({
            Id: 7,
            Name: 'Mike S.',
            Games: 0,
            Goals: 0,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 9,
            Name: 'Jason',
            Games: 1,
            Goals: 0,
            Assists: 1,
            PIM: 0,
        });

        roster.push({
            Id: 10,
            Name: 'Graham',
            Games: 1,
            Goals: 0,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 11,
            Name: 'Mike CD.',
            Games: 1,
            Goals: 0,
            Assists: 0,
            PIM: 0,
        });

        roster.push({
            Id: 12,
            Name: 'Nigel',
            Games: 1,
            Goals: 0,
            Assists: 1,
            PIM: 0,
        });

        return roster;
    }
}

export class StatService {

    constructor() {}

    public GetStatsData(): StatsDto[] {
        const stats: StatsDto[] = HardCodedData.GetStats();

        return stats;
    }    
}

