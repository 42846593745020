import React from "react";
import ReactDOM from "react-dom";
import { Cards } from "./cards";
import { HomeTable } from "./table";
import Box from '@mui/material/Box';

type Props = {

}



export const Homepage = ({ }: Props): JSX.Element => {
    return(
        <Box sx={{paddingBottom: '2rem'}}>
            <Cards></Cards>
            <HomeTable></HomeTable>
        </Box>
    );
}