export interface StatsDto {
    Id: number;
    Name: string;
    Games: number;
    Goals: number;
    Assists: number
    PIM: number
}

export interface StatsDtoWithPoints extends StatsDto {
    Points: number;
}

export class StatHelper {
    public static AddPoints(dto: StatsDto): StatsDtoWithPoints {
        const Points = dto.Goals + dto.Assists;
        return { ...dto, Points };
    }
}