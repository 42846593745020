import React from 'react';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, tableCellClasses } from '@mui/material';
import { StatHelper, StatsDto, StatsDtoWithPoints } from '../../classes/stats';
import { StatService } from '../../services/stat-service';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#3f51b5",
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

interface Column {
  id: 'Name' | 'Games' | 'Goals' | 'Assists' | 'Points' | 'PIM';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { id: 'Name', label: 'Player', minWidth: 75 },
  { 
      id: 'Games', 
      label: 'Games played', 
      minWidth: 50,
      align: 'right',
  },
  {
    id: 'Goals',
    label: 'Goals',
    minWidth: 50,
    align: 'right',
  },
  {
    id: 'Assists',
    label: 'Assists',
    minWidth: 50,
    align: 'right',
  },
  {
    id: 'Points',
    label: 'Points',
    minWidth: 50,
    align: 'right',
  },
  {
    id: 'PIM',
    label: 'PIM',
    minWidth: 50,
    align: 'right',
  },
];

const useStyles = makeStyles({
  root: {
    width: '80%',
    margin: '0 auto',
  },
  container: {
    marginTop: '2rem',
    maxHeight: 440,
  },
});

export const HomeTable = () => {
  const statService = new StatService();
  
  const GetRows = (): StatsDtoWithPoints[] => {
    const rows = statService.GetStatsData().map(p => StatHelper.AddPoints(p));

    rows.sort((a, b) => b.Points - a.Points);

    return rows;
  }

  const classes = useStyles();
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);

//   const handleChangePage = (event: unknown, newPage: number) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <Table stickyHeader size="small" aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {GetRows().map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.Id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    return (
                      <StyledTableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
  );
}
