export enum Positions {
    LeftWing,
    Center,
    RightWing,
    LeftDefense,
    RightDefense,
    Goalie
}

export class PositionHelpers {
    public static IsForward(position: Positions): boolean {
        switch (position) {
            case (Positions.LeftWing):
            case (Positions.Center):
            case (Positions.RightWing):
                return true;
            default:
                return false;
        }
    }

    public static IsDefense(position: Positions): boolean {
        switch (position) {
            case (Positions.LeftDefense):
            case (Positions.RightDefense):
                return true;
            default:
                return false;
        }
    }

    public static GetPositionName(position: Positions): string {
        switch(position) {
            
            case (Positions.LeftWing):
                return 'Left Wing';
            case (Positions.Center):
                return 'Center';
            case (Positions.RightWing):
                return 'Right Wing';
            case (Positions.LeftDefense):
                return 'Left Defense';
            case (Positions.RightDefense):
                return 'Right Defense';
            case (Positions.Goalie):
                return 'Goalie';
            default:
                return '';
        }
    }
}