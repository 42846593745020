import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { PositionHelpers, Positions } from "../../classes/position";
import { RosterDto } from "../../classes/roster";
import { LineupService } from "../../services/lineup-service";
import { Line } from "./line";

type Props = {};



export const Lineups = ({}: Props): JSX.Element => {
  const lineupService = new LineupService();

  const [ roster, setRoster ] = useState<RosterDto[]>(lineupService.GetLineupData());

  const [ forwards, setForwards ] = useState<RosterDto[]>(roster.filter((r) => PositionHelpers.IsForward(r.Position)));
  const [ defense, setDefense ] = useState<RosterDto[]>(roster.filter((r) => PositionHelpers.IsDefense(r.Position)));
  const [ goalies, setGoalies ] = useState<RosterDto[]>(roster.filter((r) => r.Position == Positions.Goalie));

  useEffect(() => {
  });

  function GetLines(position: RosterDto[]): RosterDto[][] {
    const numOfLines = position.map(x => x.Line).reduce((prev, curr) => Math.max(prev, curr));
  
    const lines: RosterDto[][] = [[]];
  
    for (let i = 1; i <= numOfLines; i++) {
      const newLine: RosterDto[] = [];
      position.filter(x => x.Line == i).sort((a, b) => a.Position - b.Position).forEach((player) => newLine.push(player));
      lines.push(FillLine(newLine));
    }
  
    return lines;
  }

  function FillLine(line: RosterDto[]): RosterDto[] {
    if (line.length == 0) return line;

    const lineNumber = line[0].Line;
    const position = line[0].Position;

    if (PositionHelpers.IsForward(position)) {
      if (line.length < 3) {
        if (!line.find(x => x.Position == Positions.LeftWing)) line.splice(0, 0, GetBlankPlayer(lineNumber, Positions.LeftWing));
        if (!line.find(x => x.Position == Positions.Center)) line.splice(1, 0, GetBlankPlayer(lineNumber, Positions.Center));
        if (!line.find(x => x.Position == Positions.RightWing)) line.push(GetBlankPlayer(lineNumber, Positions.RightWing));
      }
    } else if (PositionHelpers.IsDefense(position)) {
      if (line.length < 2) {
        if (!line.find(x => x.Position == Positions.LeftDefense)) line.splice(0, 0, GetBlankPlayer(lineNumber, Positions.LeftDefense));
        if (!line.find(x => x.Position == Positions.RightDefense)) line.push(GetBlankPlayer(lineNumber, Positions.RightDefense));
      }
    }

    return line;
  }

  function GetBlankPlayer(Line: number, Position: Positions): RosterDto {
    return {
      Id: -1,
      IsSub: false,
      Name: '',
      Line,
      Position
    }
  }

  function GetLineHtml(position: RosterDto[]) {
    const lines: RosterDto[][] = GetLines(position);
    const isCentered = true;// !PositionHelpers.IsForward(lines[0][0].Position);

    return (
      lines.map((line, index) => {
        return (
          <Line key={index} players={line} isCentered={isCentered}></Line>
        );
      })
    )
  }

  return (
    <div>
      <h1>Lineup - Grand Speed Blue</h1>

      <h2>Forwards</h2>
      { GetLineHtml(forwards) }

      <h2>Defense</h2>
      { GetLineHtml(defense) }

      <h2>Goalie</h2>
      { GetLineHtml(goalies) }

    </div>
  );
};
