import React, { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Card, CardActions, CardContent, Typography, Box } from "@mui/material";
import { StatService } from "../../services/stat-service";
import { StatHelper, StatsDtoWithPoints } from "../../classes/stats";

const useStyles = makeStyles(() =>
  createStyles({
    card1: {
      padding: '8px',
      textAlign: "center",
      backgroundColor: "#FFF5BA !important",
    },
    card2: {
      padding: '8px',
      textAlign: "center",
      backgroundColor: "#E0BBE4 !important",
    },
    card3: {
      padding: '8px',
      textAlign: "center",
      backgroundColor: "#B6CFB6 !important",
    },
    card4: {
      padding: '8px',
      textAlign: "center",
      backgroundColor: "#FFAEA5 !important",
    },
  })
);

export const Cards = () => {
  const statService = new StatService();

  const [ stats, ] = useState<StatsDtoWithPoints[]>(statService.GetStatsData().map(p => StatHelper.AddPoints(p)));

  const [ goals, ] = useState<StatsDtoWithPoints[]>(stats.filter(x => x.Goals == Math.max(...stats.map(s => s.Goals))));
  const [ assists, ] = useState<StatsDtoWithPoints[]>(stats.filter(x => x.Assists == Math.max(...stats.map(s => s.Assists))));
  const [ points, ] = useState<StatsDtoWithPoints[]>(stats.filter(x => x.Points == Math.max(...stats.map(s => s.Points))));
  const [ pim, ] = useState<StatsDtoWithPoints[]>(stats.filter(x => x.PIM == Math.max(...stats.map(s => s.PIM))));
  
  const classes = useStyles();

  return (
    <Box sx={{
        flexGrow: 1,
        margin: "24px",
      }}>
      <Grid container spacing={5}>
        <Grid item xs>
          <Card className={classes.card1}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Golden Skate - Most Goals
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                { goals.length == 1 ? goals[0].Name : `${goals[0].Name} + ${goals.length - 1} more` }
              </Typography>
              <Typography variant="body2" component="p">
                { goals[0].Goals } goals
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.card2}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Anti-Richard - Most Assists
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                { assists.length == 1 ? assists[0].Name : `${assists[0].Name} + ${assists.length - 1} more` }
              </Typography>
              <Typography variant="body2" component="p">
                { assists[0].Assists } assists
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.card3}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                Well-Rounded - Most Points
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                { points.length == 1 ? points[0].Name : `${points[0].Name} + ${points.length - 1} more` }
              </Typography>
              <Typography variant="body2" component="p">
                { points[0].Points } points
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs>
          <Card className={classes.card4}>
            <CardContent>
              <Typography color="textSecondary" gutterBottom>
                The Goon - Most PIM
              </Typography>
              <Typography variant="h5" component="h2" gutterBottom>
                { pim.length == 1 ? pim[0].Name : `${pim[0].Name} + ${pim.length - 1} more` }
              </Typography>
              <Typography variant="body2" component="p">
                { pim[0].PIM }  Minutes in the Sin Bin
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};
