import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Card, CardActions, CardContent, Typography, Box } from "@mui/material";

import { RosterDto } from "../../classes/roster";
import { PositionHelpers } from "../../classes/position";

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      padding: '8px',
      textAlign: "center",
      backgroundColor: "#3f51b5 !important",
      color: "#fff !important",
    },
    cardContent: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    lightText: {
        color: "rgba(255, 255, 255, 0.6) !important",
    }
  })
);

type Props = {
    key: number;
    players: RosterDto[];
    isCentered: boolean
};

export const Line = ({ players, isCentered }: Props): JSX.Element => {
  const classes = useStyles();
  const justify = isCentered ? 'center' : 'start';

  return (
    <Box sx={{ flexGrow: 1, margin: "24px" }}>
      <Grid container spacing={5} style={{justifyContent: `${justify}`}}>
            {
                players.map((player, index) => {
                    if (player.Id < 0) {
                        return (
                            <Grid key={index} item xs={1} md={4}></Grid>
                        );
                    }
                    return (
                        <Grid key={index} item xs={12} md={4}>
                            <Card className={classes.card}>
                                <CardContent className={classes.cardContent}>
                                    <Typography variant="h1" component="h1">
                                        {player.Id}
                                    </Typography>
                                    <Box sx={{margin: 'auto 0', paddingLeft: '2rem'}}>
                                        <Typography className={classes.lightText}>
                                            {`Line #${player.Line}`}
                                        </Typography>
                                        <Typography className={classes.lightText} gutterBottom>
                                            {PositionHelpers.GetPositionName(player.Position)}
                                        </Typography>
                                        <Typography variant="h4" component="h2" gutterBottom>
                                            {player.Name}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                }) 
            }
        </Grid>
    </Box>
  );
};
