import { Positions } from "../classes/position";
import { RosterDto } from "../classes/roster";

class HardCodedData {
     public static GetRoster(): RosterDto[] {
        const roster: RosterDto[] = [];

        roster.push({
            Id: 1,
            Name: 'Adam',
            Line: 3,
            Position: Positions.Center,
            IsSub: false
        });

        roster.push({
            Id: 6,
            Name: 'Casey',
            Line: 1,
            Position: Positions.Center,
            IsSub: false
        });

        roster.push({
            Id: 3,
            Name: 'Jasmine',
            Line: 1,
            Position: Positions.LeftWing,
            IsSub: false
        });

        roster.push({
            Id: 4,
            Name: 'Richard',
            Line: 2,
            Position: Positions.Center,
            IsSub: false
        });

        roster.push({
            Id: 5,
            Name: 'Calvin',
            Line: 2,
            Position: Positions.LeftWing,
            IsSub: false
        });

        roster.push({
            Id: 8,
            Name: 'Sean M.',
            Line: 2,
            Position: Positions.RightWing,
            IsSub: false
        });

        roster.push({
            Id: 2,
            Name: 'Mitch',
            Line: 1,
            Position: Positions.RightWing,
            IsSub: false
        });

        roster.push({
            Id: 7,
            Name: 'Mike S.',
            Line: 3,
            Position: Positions.RightWing,
            IsSub: false
        });

        roster.push({
            Id: 9,
            Name: 'Jason',
            Line: 1,
            Position: Positions.LeftDefense,
            IsSub: false
        });

        roster.push({
            Id: 10,
            Name: 'Graham',
            Line: 2,
            Position: Positions.RightDefense,
            IsSub: false
        });

        roster.push({
            Id: 11,
            Name: 'Mike CD.',
            Line: 2,
            Position: Positions.LeftDefense,
            IsSub: false
        });

        roster.push({
            Id: 12,
            Name: 'Nigel',
            Line: 1,
            Position: Positions.RightDefense,
            IsSub: false
        });

        roster.push({
            Id: 13,
            Name: 'Luc',
            Line: 1,
            Position: Positions.Goalie,
            IsSub: false
        });


        return roster;
    }
}

export class LineupService {

    constructor() {}

    public GetLineupData(): RosterDto[] {
        const lineup: RosterDto[] = HardCodedData.GetRoster();

        return lineup;
    }

    
}

